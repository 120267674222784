<template>
  <div style="width: 100%; height: 100%">
    <div id="container"></div>
    <div class="input-card" style="height: 97%">
      <div style="height: 73%; overflow: auto">
        <h4 style="font-weight: bold; text-align: center">节点信息</h4>
        <div>
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              icon="el-icon-bottom"
              type="primary"
              size="mini"
              :timestamp="activity.nodeTime"
            >
              {{ activity.nodeName }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <h4 style="font-weight: bold; text-align: center">详情明细</h4>
        <div class="myContentList">
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">司机:</div>
                <div style="float: left">{{ nodeDetails.driverName }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">车牌:</div>
                <div style="float: left">{{ nodeDetails.truckNo }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">司机电话:</div>
                <div style="float: left">{{ nodeDetails.driverMobile }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">货物信息:</div>
                <div style="float: left">
                  <el-tag size="mini">{{ nodeDetails.tioQty }}件</el-tag> <el-tag size="mini">{{ nodeDetails.tioWeight }}KG</el-tag>
                  <el-tag size="mini">{{ nodeDetails.tioVolume }}m³</el-tag>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">发车地点:</div>
                <div style="float: left">{{ nodeDetails.sendStratAddress }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                <div style="font-weight: bold; float: left">终止地点:</div>
                <div style="float: left">{{ nodeDetails.sendEndAddress }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="height: 16%; margin-top: 5px">
        <h4 style="font-weight: bold; text-align: center">播放速度</h4>
        <el-slider v-model="value1" :step="20" show-stops :format-tooltip="formatTooltip"> </el-slider>
        <h4 style="font-weight: bold; text-align: center">轨迹回放控制</h4>
        <div class="input-item">
          <input type="button" class="btn" value="开始动画" id="start" @click="startAnimation()" />
          <input type="button" class="btn" value="暂停动画" id="pause" @click="pauseAnimation()" />
        </div>
        <div class="input-item">
          <input type="button" class="btn" value="继续动画" id="resume" @click="resumeAnimation()" />
          <input type="button" class="btn" value="停止动画" id="stop" @click="stopAnimation()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  mounted() {
    this.ampStateTrajectory()
    setTimeout(() => {
      this.initMap()
    }, 1000)
  },
  beforeDestroy() {
    this.map && this.map.destroy()
  },
  data() {
    return {
      map: null,
      mapCenter: [116.397428, 39.90923],
      marker: null,
      makerCenter: [116.397428, 39.90923],
      lineArr: [],
      startPoint: [],
      endPoint: [],
      nodeDetails: {
        driverName: '',
        truckNo: '',
        driverMobile: '',
        sendStratTime: '',
        sendStratAddress: '',
        sendEndTime: '',
        sendEndAddress: '',
        tioQty: '',
        tioWeight: '',
        tioVolume: '',
      },
      activities: [],
      value1: 40,
      aMapSpeed: 500,
    }
  },
  methods: {
    formatTooltip(val) {
      if (val / 10 == 0) {
        return '初始'
      } else if (val / 10 == 2) {
        this.aMapSpeed = 200
        return '0.75倍'
      } else if (val / 10 == 4) {
        this.aMapSpeed = 500
        return '1倍'
      } else if (val / 10 == 6) {
        this.aMapSpeed = 1000
        return '1.5倍'
      } else if (val / 10 == 8) {
        this.aMapSpeed = 1500
        return '2倍'
      } else if (val / 10 == 10) {
        this.aMapSpeed = 2000
        return '2.5倍'
      }
    },
    ampStateTrajectory() {
      this.getAmapList('A', this.$route.query.orderNo)
      //订单轨迹
      axios.get(`${this.$apiPath}/api/urban/permit/track/trace/searchTraceByOrderNo?orderNo=` + this.$route.query.orderNo).then(res => {
        if (res.data.success == true) {
          const pointVos = res.data.data.pointVos
          this.makerCenter = pointVos[0].loc
          this.startPoint = res.data.data.startPoint.loc
          this.endPoint = res.data.data.endPoint.loc
          const mapList = []
          for (var i = 0; i < pointVos.length; i++) {
            const aa = pointVos[i].loc
            mapList.push(aa)
          }
          let aaa = JSON.stringify(mapList)
          this.lineArr = JSON.parse(aaa)
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
    initMap() {
      this.map = new AMap.Map('container', {
        resizeEnable: true,
        center: this.mapCenter,
        zoom: 17,
      })

      this.marker = new AMap.Marker({
        map: this.map,
        position: this.makerCenter,
        icon: 'https://webapi.amap.com/images/car.png',
        offset: new AMap.Pixel(-26, -15),
        autoRotation: true,
        angle: -90,
      })

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: '#28F', //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      })

      let passedPolyline = new AMap.Polyline({
        map: this.map,
        // path: this.lineArr,
        strokeColor: '#AF5', //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      })
      // 创建一个 Icon
      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(30, 39),
        // 图标的取图地址
        image: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        // 图标所用图片大小
        imageSize: new AMap.Size(135, 40),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-9, -3),
      })

      // 将 icon 传入 marker
      var startMarker = new AMap.Marker({
        position: new AMap.LngLat(this.startPoint[0], this.startPoint[1]),
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30),
      })
      // 创建一个 icon
      var endIcon = new AMap.Icon({
        size: new AMap.Size(30, 39),
        image: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3),
      })

      // 将 icon 传入 marker
      var endMarker = new AMap.Marker({
        position: new AMap.LngLat(this.endPoint[0], this.endPoint[1]),
        icon: endIcon,
        offset: new AMap.Pixel(-13, -30),
      })

      this.marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath)
      })
      // 将 markers 添加到地图
      var that = this
      setTimeout(function () {
        that.map.add([startMarker, endMarker])
      }, 1000)
      this.map.setFitView()
    },
    startAnimation() {
      this.marker.moveAlong(this.lineArr, this.aMapSpeed)
    },
    pauseAnimation() {
      this.marker.pauseMove()
    },
    resumeAnimation() {
      this.marker.resumeMove()
    },
    stopAnimation() {
      this.marker.stopMove()
    },
    getAmapList(stats, getData) {
      const data = new URLSearchParams()
      data.append('orderNo', getData)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/permit/tioNode/getNodeByOrderNo`,
        data: data,
      }).then(res => {
        if (res.data.tioVolume == null || res.data.tioVolume == 'null') {
          res.data.tioVolume = '0'
        }
        if (res.data.tioWeight == null || res.data.tioWeight == 'null') {
          res.data.tioWeight = '0'
        }
        if (res.data.tioQty == null || res.data.tioQty == 'null') {
          res.data.tioQty = '0'
        }
        this.nodeDetails = res.data
        const nodeTimes = res.data.nodeTimes
        for (let i = 0; i < nodeTimes.length; i++) {
          if (nodeTimes[i].nodeTime == null) {
          } else {
            nodeTimes[i].nodeTime = this.$moment(nodeTimes[i].nodeTime).format('YYYY-MM-DD HH:mm:ss')
          }
        }
        this.activities = res.data.nodeTimes
      })
    },
  },
}
</script>

<style scoped>
@import url('https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css');

#container {
  height: 100%;
  width: 100%;
}

.input-card .btn {
  margin-right: 1.2rem;
  width: 9rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
.myContentList .el-row {
  margin-bottom: 5px;
  font-size: 12px;
}
.playCard {
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  width: 50rem;
  height: 5rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  position: fixed;
  bottom: 1.5rem;
  left: 1rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;
}
.grid-content {
  font-size: 14px;
}
</style>
